import React, { useState } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  ListItem,
  makeStyles,
  Divider,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    "&.MuiListItem-gutters": {
      paddingLeft: "8px !important",
      paddingRight: "0px !important",
      color: "#ffffff"
    }
  },
  button: {
    color: "#ffffff",
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: "auto"
  },
  active: {
    color: "#ffffff",
    backgroundColor: "rgba(0, 0, 0, 0.1)",

    "& $title": {
      fontWeight: theme.typography.fontWeightMedium
    },

    "& $icon": {
      color: "#ffffff"
    }
  },
  subLinkItem: {
    cursor: "pointer",
    paddingTop: 5,
    marginBottom: 8
  },
  iconSublink: {
    marginLeft: theme.spacing(2)
  },
  listItemIcon: {
    color: "#ffffff",
    minWidth: 30
  },
  listItemText: {
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500
    }
  },
  subListItem: {
    marginTop: 0
  },
  subTitle: {
    marginLeft: 5
  }
}));

const SubLinks = ({ className, Icon, title, subLinks, showBorder }) => {
  const classes = useStyles();
  const { location } = useHistory();
  const [open, setOpen] = useState(
    subLinks.map(({ href }) => href).includes(location.pathname) || false
  );

  return (
    <>
      <ListItem
        className={clsx(classes.item, className, classes.subLinkItem)}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {Icon && <Icon />}
        </ListItemIcon>
        <ListItemText primary={title} className={classes.listItemText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subLinks.map((item, index) => (
          <ListItem
            key={`${title}-${index}`}
            className={clsx(
              classes.item + " " + classes.subListItem,
              className
            )}
            disableGutters
          >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={RouterLink}
              to={item.href}
            >
              {item.icon && (
                <item.icon className={classes.iconSublink} size="20" />
              )}
              <span className={classes.subTitle}>{item.title}</span>
            </Button>
          </ListItem>
        ))}
      </Collapse>
      {showBorder && (
        <Divider style={{ backgroundColor: "rgb(255 255 255 / 12%)" }} />
      )}
    </>
  );
};

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  showBorder,
  subLinks,
  ...props
}) => {
  const classes = useStyles();

  if (subLinks && subLinks.length > 0)
    return (
      <SubLinks
        className={className}
        Icon={Icon}
        title={title}
        subLinks={subLinks}
        showBorder={showBorder}
      />
    );

  return (
    <>
      <ListItem className={clsx(classes.item, className)} disableGutters>
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
        </Button>
      </ListItem>
      {showBorder && (
        <Divider style={{ backgroundColor: "rgb(255 255 255 / 12%)" }} />
      )}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
