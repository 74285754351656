//@ts-check
import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetImageByFolderId = async (folderId, sortExpression, name) => {
  try {
    const params = new URLSearchParams();
    folderId && params.append("folderId", folderId);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("title", name);
    const res = await service.get(API_URL.GetMediasByFolderId, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetImageFolders = async () => {
  try {
    const res = await service.get(API_URL.GetImageCategories);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateImageCategory = async data => {
  try {
    const res = await service.post(API_URL.CreateImageCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateImageCategory = async data => {
  try {
    const res = await service.post(API_URL.UpdateCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteImageCategory = async id => {
  try {
    const params = new URLSearchParams();
    params.append("Id", id);
    const res = await service.postParams(API_URL.DeleteImageCategory, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteMedia = async data => {
  try {
    const params = new URLSearchParams();
    (data || []).map(({ id }) => {
      params.append("Ids", id);
    });

    const res = await service.postParams(API_URL.DeleteMedia, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateMedia = async data => {
  const formData = new FormData();
  formData.append("CategoryId", data?.categoryId || "");
  formData.append("Taker", data?.taker || "");
  formData.append("Device", data?.device || "");
  formData.append("Title", data?.title || "");
  formData.append("file", data?.file);
  formData.append("thumbnail", data?.thumbnail);
  try {
    const res = await service.post(API_URL.CreateMedia, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateMediaGallery = async data => {
  try {
    const formData = new FormData();
    formData.append("Id", data?.id);
    formData.append("CategoryId", data?.categoryId || "");
    formData.append("Taker", data?.taker || "");
    formData.append("Device", data?.device || "");
    formData.append("Title", data?.title || "");
    formData.append("file", data?.file);
    formData.append("FileUrl", data?.fileUrl);
    formData.append("thumbnail", data?.thumbnailTemp || data?.thumbnail);
    const res = await service.post(API_URL.UpdateMedia, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
