import React from "react";
import { Box, Drawer, List, makeStyles, Divider } from "@material-ui/core";
import NavItem from "./NavItem";
import { ROUTER_NAME } from "src/constants";

//--- Icon
import BuildIcon from "@material-ui/icons/Build";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GavelIcon from "@material-ui/icons/Gavel";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MapIcon from "@material-ui/icons/Map";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import StreetviewIcon from "@material-ui/icons/Streetview";
import PolymerIcon from "@material-ui/icons/Polymer";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LinkIcon from '@material-ui/icons/Link';

const items = [
  {
    title: "Quản lý công cụ",
    icon: BuildIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.TOOL,
        icon: FiberManualRecordIcon,
        title: "Công cụ",
        showBorder: true
      },
      {
        href: ROUTER_NAME.TOOL_TYPE,
        icon: FiberManualRecordIcon,
        title: "Loại công cụ",
        showBorder: true
      }
    ]
  },
  // {
  //   href: ROUTER_NAME.TOOL,
  //   icon: BuildIcon,
  //   title: "Công cụ",
  //   showBorder: true
  // },
  // {
  //   href: ROUTER_NAME.TOOL_TYPE,
  //   icon: FiberManualRecordIcon,
  //   title: "Loại công cụ",
  //   showBorder: true
  // },
  {
    title: "Quản lý bản đồ",
    icon: MapIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.MAP_LAYER,
        icon: FiberManualRecordIcon,
        title: "Lớp bản đồ",
        showBorder: true
      },
      {
        href: ROUTER_NAME.BASE_MAP_SETTING,
        icon: FiberManualRecordIcon,
        title: "Bản đồ nền",
        showBorder: true
      }
    ]
  },
  // {
  //   href: ROUTER_NAME.MAP_LAYER,
  //   icon: LayersIcon,
  //   title: "Lớp bản đồ",
  //   showBorder: true
  // },
  // {
  //   href: ROUTER_NAME.BASE_MAP_SETTING,
  //   icon: MapIcon,
  //   title: "Quản lý Bản đồ nền",
  //   showBorder: true
  // },
  {
    href: ROUTER_NAME.OBJECT_MANAGEMENT,
    icon: StreetviewIcon,
    title: "Quản lý Đối Tượng",
    showBorder: true
  },
  {
    title: "Quản lý tài liệu",
    icon: NoteAddIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.MAP_DOCUMENT,
        icon: FiberManualRecordIcon,
        title: "Tài liệu Bản đồ",
        showBorder: true
      },
      {
        href: ROUTER_NAME.DOCUMENT,
        icon: FiberManualRecordIcon,
        title: "Tài liệu Văn bản",
        showBorder: true
      },
      {
        href: ROUTER_NAME.IMAGE_GALLERY,
        icon: FiberManualRecordIcon,
        title: "Thư viện ảnh",
        showBorder: true
      },
      {
        href: ROUTER_NAME.VIDEO_GALLERY,
        icon: FiberManualRecordIcon,
        title: "Thư viện video",
        showBorder: true
      }
    ]
  },
  // {
  //   href: ROUTER_NAME.MAP_DOCUMENT,
  //   icon: NoteAddIcon,
  //   title: "Tài liệu Bản đồ",
  //   showBorder: true
  // },
  // {
  //   href: ROUTER_NAME.DOCUMENT,
  //   icon: NoteAddIcon,
  //   title: "Tài liệu Văn bản",
  //   showBorder: true
  // },
  {
    icon: AccountCircleIcon,
    title: "Quản lý người dùng",
    subLinks: [
      {
        href: ROUTER_NAME.USER_MANAGEMENT,
        title: "Tài khoản",
        showBorder: true,
        icon: FiberManualRecordIcon
      },
      {
        href: ROUTER_NAME.GROUP_MANAGEMENT,
        title: "Nhóm người dùng",
        showBorder: true,
        icon: FiberManualRecordIcon
      }
    ]
  },
  {
    title: "Quản lý khác",
    icon: InboxIcon,
    subLinks: [
      {
        href: ROUTER_NAME.TERM,
        icon: GavelIcon,
        title: "Điều khoản",
        showBorder: true
      },
      {
        href: ROUTER_NAME.GUIDE,
        icon: AssignmentIcon,
        title: "Hướng dẫn",
        showBorder: true
      },
      {
        href: ROUTER_NAME.FEEDBACK,
        icon: FeedbackIcon,
        title: "Phản hồi",
        showBorder: true
      },
      {
        href: ROUTER_NAME.NORMAL_INFO,
        icon: PolymerIcon,
        title: "Thay đổi logo",
        showBorder: true
      },
      {
        href: ROUTER_NAME.FEEDBACK_INFO,
        icon: ContactSupportIcon,
        title: "Thay đổi liên hệ",
        showBorder: true
      },
      {
        href: ROUTER_NAME.VR360_LINK_MANAGEMENT,
        icon: LinkIcon,
        title: "Link VR360",
        showBorder: true
      }
    ]
  }
];

const useStyles = makeStyles(() => ({
  desktopDrawer: {
    width: 240,
    top: 70,
    height: "calc(100% - 64px)",
    backgroundColor: "#354ea9"
  }
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <Drawer
      open
      anchor="left"
      classes={{ paper: classes.desktopDrawer }}
      variant="persistent"
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Box p={2}>
          <List>
            <Divider style={{ backgroundColor: "rgb(255 255 255 / 12%)" }} />
            {items.map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                subLinks={item?.subLinks || null}
                {...item}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
      </Box>
    </Drawer>
  );
};

export default NavBar;
