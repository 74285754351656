export const ROUTER_NAME = {
  ALL: "*",
  DEFAULT: "/",
  NOT_FOUND: "/404",
  LOGIN: "/dang-nhap",
  REGISTER: "/dang-ki",
  TOOL: "/cong-cu",
  TOOL_TYPE: "/loai-cong-cu",
  MAP_LAYER: "/lop-ban-do",
  MAP_DOCUMENT: "/tai-lieu-ban-do",
  FORGOT_PASSWORD: "/quen-mat-khau",
  RESET_PASSWORD: "/thay-mat-khau",
  USER_MANAGEMENT: "/quan-ly-nguoi-dung",
  GUIDE: "/huong-dan",
  FEEDBACK: "/phan-hoi",
  TERM: "/dieu-khoan",
  DOCUMENT: "/van-ban",
  IMAGE_GALLERY: "/thu-vien-anh",
  VIDEO_GALLERY: "/thu-vien-video",
  BASE_MAP_SETTING: "/ban-do-nen",
  NORMAL_INFO: "/thong-tin-chung",
  FEEDBACK_INFO: "/thong-tin-gop-y",
  OBJECT_MANAGEMENT: "/quan-ly-doi-tuong",
  GROUP_MANAGEMENT: "/quan-ly-nhom-nguoi-dung",
  VR360_LINK_MANAGEMENT: "/quan-ly-link-vr360"
};
