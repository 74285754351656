import { useDispatch } from "react-redux";
import { ACTIONS_TYPES } from "src/constants";
export const SET_LOADING = "SET_LOADING";

const initialState = {
  loading: false
};

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};

export const showLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading
});

export const useSetLoading = () => {
  const dispatch = useDispatch();
  const openShowLoading = () => dispatch(showLoading(true));
  const hideLoading = () => dispatch(showLoading(false));
  return { openShowLoading, hideLoading };
};
