import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetHomePageMapViewData = async (mapId = 1) => {
  try {
    const params = new URLSearchParams();
    params.append("mapId", mapId);
    const res = await service.get(API_URL.GetHomePageMapViewData, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateLayer = async (data, files) => {
  try {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));
    if (files && files.length > 0) {
      files.map(file => {
        formData.append("images", file);
      });
    }
    const res = await service.post(API_URL.UpdateLayer, formData);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetPgSchema = async () => {
  try {
    const res = await service.get(API_URL.GetPgSchema);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetMedia = async (mediaType, objectId) => {
  try {
    let url;
    switch (mediaType) {
      case 0:
        url = API_URL.Get2DImagesOfObject;
        break;
      case 1:
        url = API_URL.Get3DImagesOfObject;
        break;
      case 2:
        url = API_URL.GetVideosOfObject;
        break;
      case 3:
        url = API_URL.GetTour360OfObject;
        break;
      case 4:
        url = API_URL.GetDocumentsOfObject;
        break;
    }
    const params = new URLSearchParams();
    params.append("objectId", objectId);
    const res = await service.get(url, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateMedia = async (mediaType, formData, isCreate = false) => {
  try {
    let url = "";
    switch (mediaType) {
      case 0:
        url = API_URL.Update2DImagesForObject;
        break;
      case 1:
        url = API_URL.Update3DImagesForObject;
        break;
      case 2:
        url = API_URL.UpdateVideosForObject;
        break;
      case 3:
        url = API_URL.UpdateTour360ForObject;
        break;
      case 4:
        if (isCreate) {
          url = API_URL.CreateDocumentForObject;
        } else {
          url = API_URL.UpdateDocumentForObject;
        }
        break;
    }
    const res = await service.post(url, formData);
    return res;
  } catch (err) {
    throw err;
  }
};

export const AutoImportShapeFile = async formData => {
  try {
    const res = await service.post(API_URL.AutoImportShapeFile, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
