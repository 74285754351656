export * from "src/config";
export const API_URL = {
  Login: "api/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPasswordAccount: "api/Account/ResetPassword",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",

  GetHomePageMapViewData: "api/cms/AdminHomePage/GetHomePageMapViewData",
  UpdateLayer: "api/cms/AdminHomePage/UpdateLayerHaveImage",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",

  GetListMapTool: "api/cms/AdminHomePage/GetListMapTool",
  GetLookupMapTool: "api/cms/AdminHomePage/GetLookupMapTool",
  GetDetailMapTool: "api/cms/AdminHomePage/GetDetailMapTool",
  UpdateMapTool: "api/cms/AdminHomePage/UpdateMapTool",

  GetLookupCategory: "api/cms/AdminHomePage/GetLookupCategory",
  CreateToolCategory: "api/cms/AdminHomePage/CreateMapToolCategory",
  UpdateCategory: "api/cms/AdminHomePage/UpdateCategory",
  DeleteCategory: "api/cms/AdminHomePage/DeleteMapToolCategory",

  GetUserManagement: "api/admin/User/GetUserManagement",
  GetUserDetail: "api/admin/User/GetUserDetail",
  CreateUser: "api/admin/User/CreateUser",
  UpdateUser: "api/admin/User/UpdateUser",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",
  ResetPassword: "api/admin/User/ResetPassword",
  DeleteUser: "api/admin/User/DeleteUser",

  GetTutorials: "api/cms/AdminIntroduce/GetTutorials",
  GetTerms: "api/cms/AdminIntroduce/GetTerms",
  UpdateTutorials: "api/cms/AdminIntroduce/UpdateTutorials",
  UpdateTerms: "api/cms/AdminIntroduce/UpdateTerms",

  GetMapPlanningByFolderId: "api/cms/AdminMapPlanning/GetMapPlanningByFolderId",
  GetMapPlanningFolders: "api/cms/AdminMapPlanning/GetMapPlanningFolders",
  CreateMapPlanningCategory:
    "api/cms/AdminMapPlanning/CreateMapPlanningCategory",
  DeleteMapPlanningCategory:
    "api/cms/AdminMapPlanning/DeleteMapPlanningCategory",
  CreateMapPlanning: "api/cms/AdminMapPlanning/CreateMapPlanning",
  UpdateMapPlanning: "api/cms/AdminMapPlanning/UpdateMapPlanning",
  DeleteMapPlanning: "api/cms/AdminMapPlanning/DeleteMapPlanning",

  GetMapDocumentByFolderId: "api/cms/AdminMapDocument/GetMapDocumentByFolderId",
  GetMapDocumentFolders: "api/cms/AdminMapDocument/GetMapDocumentFolders",
  CreateMapDocument: "api/cms/AdminMapDocument/CreateMapDocument",
  UpdateMapDocument: "api/cms/AdminMapDocument/UpdateMapDocument",
  DeleteMapDocument: "api/cms/AdminMapDocument/DeleteMapDocument",
  CreateMapDocumentCategory:
    "api/cms/AdminMapDocument/CreateMapDocumentCategory",
  DeleteMapDocumentCategory:
    "api/cms/AdminMapDocument/DeleteMapDocumentCategory",

  GetListFeedbacks: "/api/cms/AdminFeedback/GetListFeedbacks",

  CreateBaseMap: "api/cms/AdminBaseMapSetting/Create",
  UpdateBaseMap: "api/cms/AdminBaseMapSetting/Update",
  DeleteBaseMap: "api/cms/AdminBaseMapSetting/Delete",
  GetListBaseMapSetting: "api/cms/AdminBaseMapSetting/GetListBaseMapSetting",
  ValidateLayerType: "api/cms/AdminBaseMapSetting/ValidateLayerType",

  GetWebCommonViewData: "api/cms/AdminHomePage/GetWebCommonViewData",
  UpdateWebCommon: "api/cms/AdminHomePage/UpdateWebCommon",
  GetFeedbackContent: "api/cms/AdminFeedback/GetFeedbackContent",
  UpdateFeedbackContent: "api/cms/AdminFeedback/UpdateFeedbackContent",

  Get2DImagesOfObject: "api/cms/AdminMediaSetup/Get2DImagesOfObject",
  Update2DImagesForObject: "api/cms/AdminMediaSetup/Update2DImagesForObject",
  Get3DImagesOfObject: "api/cms/AdminMediaSetup/Get3DImagesOfObject",
  Update3DImagesForObject: "api/cms/AdminMediaSetup/Update3DImagesForObject",
  GetVideosOfObject: "api/cms/AdminMediaSetup/GetVideosOfObject",
  UpdateVideosForObject: "api/cms/AdminMediaSetup/UpdateVideosForObject",
  GetTour360OfObject: "api/cms/AdminMediaSetup/GetTour360OfObject",
  UpdateTour360ForObject: "api/cms/AdminMediaSetup/UpdateTour360ForObject",
  GetDocumentsOfObject: "api/cms/AdminMediaSetup/GetDocumentsOfObject",
  CreateDocumentForObject: "api/cms/AdminMediaSetup/CreateDocumentForObject",
  UpdateDocumentForObject: "api/cms/AdminMediaSetup/UpdateDocumentForObject",
  DeleteDocumentForObject: "api/cms/AdminMediaSetup/DeleteDocumentForObject",

  CreateFeatures: "api/admin/PgTable/insert-layer-object",
  UpdateFeatures: "api/admin/PgTable/update-layer-object",
  DeleteFeatures: "api/admin/PgTable/delete-layer-object",
  GetMapStyle: "api/admin/Utils/get-map-style",
  AutoImportShapeFile: "api/admin/Utils/auto-import-shape-file",

  GetLookupGroup: "api/admin/User/GetGroupUserLookup",
  CreateGroup: "api/admin/User/CreateGroupUser",
  UpdateGroup: "api/admin/User/UpdateGroupUser",
  DeleteGroup: "api/admin/User/DeleteGroupUser",

  GetMediasByFolderId: "api/cms/AdminMediaLibrary/GetMediasByFolderId",
  CreateMedia: "api/cms/AdminMediaLibrary/CreateMedia",
  UpdateMedia: "api/cms/AdminMediaLibrary/UpdateMedia",
  DeleteMedia: "api/cms/AdminMediaLibrary/DeleteMedia",
  CreateImageCategory: "api/cms/AdminMediaLibrary/CreateImageCategory",
  DeleteImageCategory: "api/cms/AdminMediaLibrary/DeleteImageCategory",
  GetImageCategories: "api/cms/AdminMediaLibrary/GetImageCategories",
  GetVideoCategories: "api/cms/AdminMediaLibrary/GetVideoCategories",
  CreateVideoCategory: "api/cms/AdminMediaLibrary/CreateVideoCategory",
  DeleteVideCategory: "api/cms/AdminMediaLibrary/DeleteVideoCategory",

  CreateVr360: "api/cms/AdminMediaLibrary/CreateLinkVR360",
  UpdateVr360: "api/cms/AdminMediaLibrary/UpdateLinkVR360",
  DeleteVr360: "api/cms/AdminMediaLibrary/DeleteLinkVR360",
  GetLookupVr360: "api/cms/AdminMediaLibrary/GetListLinkVR360?"
};
