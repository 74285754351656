import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetListFeedbacks = async (
  pageIndex = 1,
  pageSize = 10,
  sortExpression = "",
  title = ""
) => {
  try {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    title && params.append("title", title);
    const res = await service.get(API_URL.GetListFeedbacks, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetFeedbackContent = async () => {
  try {
    const res = await service.get(API_URL.GetFeedbackContent);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateFeedbackContent = async data => {
  const formData = new FormData();
  formData.append("FeedbackContent", data.feedbackContent);
  formData.append("FeedbackAddress", data.feedbackAddress);
  formData.append("FeedbackPhoneNumber", data.feedbackPhoneNumber);
  formData.append("feedbackEmail", data.feedbackEmail);
  formData.append("FeedbackBackground", data.feedbackBackground);
  formData.append("GoogleMapEmbed", data.googleMapEmbed);
  data.file && formData.append("file", data.file);
  try {
    const res = await service.post(API_URL.UpdateFeedbackContent, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
