//@ts-check
import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetVideoCategories = async () => {
  try {
    const res = await service.get(API_URL.GetVideoCategories);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateVideoCategory = async data => {
  try {
    const res = await service.post(API_URL.CreateVideoCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateVideoCategories = async data => {
  try {
    const res = await service.post(API_URL.UpdateCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteVideCategory = async id => {
  try {
    const params = new URLSearchParams();
    params.append("Id", id);
    const res = await service.postParams(API_URL.DeleteVideCategory, params);
    return res;
  } catch (err) {
    throw err;
  }
};
